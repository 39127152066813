<template>
  <div class="p-b-30">
    <p class="un-title">{{xhrData.title}}</p>
    <div class="un-text">
      {{xhrData.content}}
    </div>
    <template v-if="xhrData.imgUrlList&&xhrData.imgUrlList.length>0">
      <img v-for="(item,index) in xhrData.imgUrlList" :key="index" :src="item" class="un-image">
    </template>
    <p class="un-link-box">
      附件：
      <a v-for="(item,index) in xhrData.attachmentUrlList" :key="index" :href="item" target="_blank">{{index}}</a>
    </p>
  </div>
</template>

<script>
import { getTrainingMaterials } from "@/api/university";

export default {
  data () {
    return {
      xhrData: {}
    };
  },
  created () {
    this.getData();
  },
  methods: {
    getData () {
      getTrainingMaterials({
        trainingMaterialsId: this.$route.params.id
      }).then(xhr => {
        this.xhrData = xhr.data;
      });
    }
  },
  components: {}
};
</script>

<style scoped>
.un-title {
  margin: 0.4rem auto 0.3rem;
  text-align: center;
  font-size: 0.17rem;
  color: #30353d;
}
.un-text {
  margin: auto;
  width: 3.45rem;
  line-height: 0.2rem;
  font-size: 0.14rem;
  color: #444851;
}
.un-image {
  width: 3.45rem;
  object-fit: cover;
  margin: 0.2rem auto 0;
}
.un-link-box {
  margin: 0.2rem auto 0;
  width: 3.45rem;
  font-size: 0.14rem;
  color: #30353d;
}
.un-link-box a{
  display: block;
}
.p-b-30 {
  padding-bottom: 0.3rem;
}
</style>
